/* Application-wide Styles */
h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}
h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}
body {
  margin: 0;
  padding: 0;
}
body, input[type="text"], button {
  color: #333;
  font-family: Cambria, Georgia, serif;
}
/* everywhere else */
* {
  font-family: Arial, Helvetica, sans-serif;
}

.legend {
  font-size: 1.37em;
  font-weight: bold;
}

.mb-3 {
  margin: 2em 0;
}

.array-input-container{
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  padding: 2em;
}

.date-picker-input-field .mat-mdc-form-field-infix
{
  padding: 0px !important;
}